import React, { useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { RootContext } from '../../reducer';
import {Input,Card,Button,Row,Form,Layout,notification} from 'antd';
import axios from 'axios';
import {UserOutlined,EyeTwoTone,EyeInvisibleOutlined} from '@ant-design/icons';

const {Content} = Layout;

const Login:React.FC=()=>{
    const {user,dispatch} = useContext(RootContext);
    let navigate = useNavigate();
    async function onFinish(e:any) {
            const promise = await axios.post('/login',{"email":e.email,"password":e.password,"type":"user_login","from":"https://tsp.whu.edu.cn"});
            //console.log(promise.data)
            if (promise.data['status'])
            {
                user.isLogin = true;
                notification.open({
                    message: 'Login status',
                    description:
                      'Login success!',
                    onClick: () => {
                      //console.log('Notification Clicked!');
                    },
                  });
                user.userEmail = e.email;
                user.userToken = promise.data['token'];
                localStorage.setItem("lisite_user",JSON.stringify(user));
                dispatch({type:'loginSuccess',email:e.email,token:promise.data['token']})
                navigate('/Home')
                //console.log(user)
            }    
    }
    let onFinishFailed = (e:any)=>{
        dispatch({type:'loginFailed',email:e.email,token:''})
        //console.log('Login Failed:', e.email);  
        
    }
    return(
            <Content>    
            <Row justify="center" align="middle">
                <Card title="Login">
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input placeholder="Email" suffix={<UserOutlined/>}/>
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                placeholder="Press enter to submit"
                                iconRender={visible =>(visible?<EyeTwoTone/>:<EyeInvisibleOutlined/>)}
                            />
                        </Form.Item>
                    
                        <Form.Item wrapperCol={{ offset: 8 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            
                        </Form.Item>
                    </Form>
                </Card>
            </Row>
            </Content>
    );
    
}

export default Login;
import type { MenuProps } from 'antd';

const routeItems: MenuProps['items'] = [
  {
    label: 'Home',
    key: 'Home',
  },
    /*{
      label: 'Home',
      key: 'Home',
      style:{position:'absolute',top:0,left:0}
    },
    {
      label: 'Members',
      key: 'Members'
    },
    {
      label: 'Research',
      key: 'Research'
    },
    {
      label: 'Publications',
      key: 'Publications'
    },
    */
    {
      label: 'Xster',
      key: 'Xster'
    },
    {
      label: 'About',
      key: 'About',
    },
    /*
    {
      label: 'Position',
      key: 'Position'
    },
    */
    /*{
      label: 'About',
      key: 'About',
      style:{position:'absolute',top:0,right:0}
    }*/
  ]
  
export default routeItems;

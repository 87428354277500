import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
//import rehypeHighlight from 'rehype-highlight'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
//import '../github-markdown.css'
import 'github-markdown-css/github-markdown-light.css'

const Markdown:React.FC<{md:string}>=(para)=> {
        const {md} = para
        return(
        <ReactMarkdown 
	          className="markdown-body"
            remarkPlugins={[remarkGfm, remarkMath]} 
            rehypePlugins={[rehypeKatex]}
            children={md}
            components={{
                code({node, inline, className, children}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <SyntaxHighlighter
                        showLineNumbers={true}
                        wrapLines={true}
                        children={String(children).replace(/^\s+|\s+$/g, '')}
                        style={vscDarkPlus}
                        language={match[1]}
                        PreTag="div"
                      
                      />
                    ) : (
                      <code className={className}>
                        {children}
                      </code>
                    )
                  }
                }}
        />
        )
        }
    

export default Markdown;

//import type { MenuProps } from 'antd';
import { Layout, Menu, Affix, MenuProps } from 'antd';
import { CSSProperties,useState,useContext } from 'react';
//import {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import { RootContext } from './reducer';
import routeItems from './router';
const { Header} = Layout;

/*
const items1: MenuProps['items'] = ['Home', 'Members','Research', 'Publications', 'Xster', 'Position','About'].map(key => (
  {
  key,
  label: `${key}`
 }
));
*/
const centerStyle:CSSProperties = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center'
};
const routeLogedItems: MenuProps['items'] = [
  {
    label: 'Home',
    key: 'Home',
  },
  {
    label: 'Xster',
    key: 'Xster',
  },
  {
    label: 'Chat',
    key: 'Chat',
  },
  {
    label: 'Logout',
    key: 'Logout',
    style:{position:'absolute',top:0,right:0}
  }
]
const LNavbar: React.FC = () => {
  let navigate = useNavigate();  
  const handleClick = (e:any) => {
    if (e.key === 'Logout')
    {
      user.isLogin = false
      user.userEmail = ''
      user.userToken = ''
      dispatch({type:'',email:'',token:''})
      localStorage.removeItem('lisite_user')
      navigate('/Home')
    }
    else
    {navigate(`/${e.key}`);}
  }
  const {user,dispatch} = useContext(RootContext)
  const navItems = user.isLogin?routeLogedItems:routeItems
  return (
  <Affix offsetTop={0}>  
  <Header className="header">
      <Menu style={centerStyle} onClick={handleClick} theme="dark" mode="horizontal" defaultSelectedKeys={['Home']} items={navItems} />
  </Header>
  </Affix>
  
  )
};
export default LNavbar;
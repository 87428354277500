import React, {useEffect,useState}  from 'react';
import { Layout } from 'antd';
//import {NavLink} from 'react-router-dom';
import axios from 'axios';
import Markdown from '../../Markdown';
const { Content } = Layout;

const Home:React.FC = () => {
  const [siteIntro,setSiteIntro] = useState<string>('');
  const getInfo = async ()=>{
    const promise = await axios.get('/circle9/lisite.introduction/0');
    setSiteIntro(promise.data.split('\n\n')[1]);
    //console.log(promise);  
  }
  useEffect(
    ()=>{
        getInfo()
    },[]
  );
  return(
      <Content style={{margin:'20px'}}>
        <Markdown md={siteIntro}/>
      </Content> 
    );
  }

  export default Home;
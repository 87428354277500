import {FC} from 'react';
import { Route,Routes } from 'react-router-dom';
import Home from '../views/Home';
import Research from '../views/Research';
import Publications from '../views/Publications';
import Login from '../views/Login';
import { Content } from 'antd/lib/layout/layout';
import Xster from '../views/Xster';
import Admin from '../views/Admin';
import Chat from '../views/Chat';
const LRouter: FC = () => {
    return (
        <Content className="mainView">
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/Home" element={<Home/>}/>
                <Route path="/Research" element={<Research/>}/>
                <Route path="/Publications" element={<Publications/>}/>
                <Route path="/Xster" element={<Xster/>}/>
                <Route path="/Login" element={<Login/>}/>
                <Route path='/Admin' element={<Admin/>}/>
                <Route path='/Chat' element={<Chat/>}/>
            </Routes>
        </Content>
    );
};    

export default LRouter;

import React, { Component } from "react";


class Publications extends Component{
    render() {
        return (
            <p>testing!</p>
        );
    }
}
export default Publications;
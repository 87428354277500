//import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import Markdown from '../../Markdown';
import { FC, useState,useEffect, useLayoutEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const { Content, Sider } = Layout;

interface Items {
  key: string;
  label: string;
  children?: Items[];
}
interface Query {
  key:string;
  path:string;
  queryType:string
}
interface SubKey {
  title:string;
  key:string;
  path:string
}
const Xster:FC = () => {   
  const xsterItems:Items[] = [
    {key:'introduction',
     label:'Introduction'},
    { 
      key:'softwares',
      label:'Softwares',
      children:[]
    },
    {
      key:'system',
      label:'System settings',
      children:[]
    },
    { key:'linux',
      label:'Linux tips',
      children:[]
    }
  ]
  const [refresh,setrefresh] = useState(false);
  const doRefresh = ()=> setrefresh(true);
  const [currentMdFile,setcurrentMdFile] = useState<string>('');
  const [xsterSiderBar,setxsterSiderBar] = useState<Items[]>(xsterItems);
  //const [count,setcount] = useState(0);
  const xster_intro_url = '/circle9/xster.introduction/0';
  /*
  const xster_sideBar_url = ['/circle9/xster-softwares/0','/circle9/xster-system/0','/circle9/linuxTips/0'];  
  const getXsterSiderBar =
    async(url:string[])=>{
      if (count !== 0)
      for (var j=0;j<3;j++){
        const promise = await axios.get(url[j]);
        let data = promise.data;
        data = data.split('\n');
        for(var i=0;i<data.length -2; i++){
          let title = data[i+2].split('# ')[1];
          (xsterItems[j+1].children as Items[]).push({"key":title,"label":title});
        }
      }
      setxsterSiderBar(xsterItems);
      if (refresh === false)
        doRefresh();
      setcount(count+1);
      //console.log(refresh);
      //console.log(count);
  }
  
  useLayoutEffect(
    ()=>{
        getXsterSiderBar(xster_sideBar_url);          
    },[refresh]
  );
  */
 const [xsterSiderBarSubKeys,setxsterSiderBarSubKeys] = useState<SubKey[]>([]);
 const xsterSiderBarQueryKeys = [
  {'key':'xster','path':'.softwares','queryType':'subKey'},
  {'key':'xster','path':'.system','queryType':'subKey'},
  {'key':'linuxTips','path':'','queryType':'subKey'}
  ]
  const subKeys:SubKey[] = [];
  const getXsterSiderBar =
  async(queries:Query[])=>{
    if (refresh === true){
    for (var j=0;j<3;j++){
      const promise = await axios.post('/circle9/pull',queries[j]);
      let data = promise.data;
      //console.log(data)
      for(var i=0;i<data['subKey'].length; i++){
        let title = data['title'][i];
        (xsterItems[j+1].children as Items[]).push({"key":title,"label":title});
        subKeys.push({'title':title,'key':data['subKey'][i],'path':data['path'][i]})
      }
    }
  }
    setxsterSiderBar(xsterItems);
    setxsterSiderBarSubKeys(subKeys);
    if (refresh === false)
      doRefresh();
    //console.log(refresh);
}

useLayoutEffect(
  ()=>{
      getXsterSiderBar(xsterSiderBarQueryKeys);          
  },[refresh]
);

  const getXsterMdFile = async (url:string)=>{
    const promise = await axios.get(url);
    setcurrentMdFile(promise.data);
    //console.log(promise.data);  
  }
  useEffect(
    ()=>{
      getXsterMdFile(xster_intro_url);          
    },[]
  );
  
  const handleClick = (e:any) => {
    if (e.key==='introduction')
      {getXsterMdFile(xster_intro_url)}
    else
      {
        let link = xsterSiderBarSubKeys.find((value)=>{return value.title === e.key})
        var query_url = '/circle9/'
        if (link?.path === '.')
          query_url = query_url+link?.key+'/7';
        else
          query_url = query_url+link?.key+link?.path+'/7';
        getXsterMdFile(query_url)
      }
    }
  return ( 
      <Content>
      <Layout
      >
        <Sider width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['introduction']}
            defaultOpenKeys={[]}
            style={{
              height: '100%',
            }}
            onClick = {handleClick}
            items={xsterSiderBar}
          />
        </Sider>
        <Content
          style={{
            padding: '10px 20px',
            minHeight: '100%',
            backgroundColor:'#fff'
          }}
        >
          <Markdown md={currentMdFile}/>
          
        </Content>
      </Layout>
      </Content>
);
        }

export default Xster;
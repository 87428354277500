//import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Layout, Menu, Alert,notification } from 'antd';
import Markdown from '../../Markdown';
import { FC, useState,useEffect, useContext } from 'react';
//import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RootContext,initialUserState } from '../../reducer';
const {TextArea} = Input;
const { Content, Sider } = Layout;
interface Items {
  key: string;
  label: string;
  children?:Items[]
}
interface Query {
  query: string;
}
const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const Chat:FC = () => {   
  const sideItems:Items[] = [{
    key: "model",
    label: "Model: ",
    children:[]
  },
  {
    key: "chats",
    label:"Chats",
    children:[{key:"current chat",label:"current chat"}]
  }
  ]
  const [refresh,setrefresh] = useState(false);
  const doRefresh = ()=> setrefresh(true);
  const [sideBar,setsideBar] = useState<Items[]>(sideItems);
  const [input, setInput] = useState('');
  const [currentMdFile,setcurrentMdFile] = useState<string>('');
  const [disableInput,setDisableInput] = useState(false);
  const {user,dispatch} = useContext(RootContext)
  const getResponse =
  async(query:Query)=>{
    const promise = await axios.post('/chat',query,{headers:{"Authorization":"JWT "+user.userToken}});
    let data = promise.data;
    //console.log(data)
    const response = data['response']; 
    setcurrentMdFile("Query: " + input +"\n\n" + 
          "Response: "
          +response+
          "\n\n"+currentMdFile)
    setDisableInput(false)
    let queryIputBox = document.getElementById("queryInputBox")
    queryIputBox?.focus()
    //console.log(data)
  }
  const getModels =
  async()=>{
    if (refresh === true){
      //console.log(user.userToken)
      const promise = await axios.get('/chat/models');
      let data = promise.data;
      const newSideBar = sideItems;
      newSideBar[0].label = newSideBar[0].label+data['current_model']
      for(var i=0;i<data['type'].length; i++){
        let title = data['type'][i] +'-'+ data['finetune'][i];
        (newSideBar[0].children as Items[]).push({"key":title,"label":title});
        }
      setsideBar(newSideBar);
    }
    if (refresh === false)
      doRefresh();
    //console.log(refresh);
}
const handleClick = async(e:any) => {
  
  if (e.keyPath[1]==='model')
    {
      setDisableInput(true)
      const promise = await axios.post("/chat/models",
      {"path":e.key},
      {headers:{"Authorization":("JWT "+user.userToken)}})
      let data = promise.data
      if (data["success"])
        {
          notification.open({
            message: 'Model have been changed successfully!',
            description:
              '',
            onClick: () => {
              //console.log('Notification Clicked!');
            },
          });
          sideBar[0]['label'] = e.key
          setsideBar(sideBar)
          setDisableInput(false)
          let queryIputBox = document.getElementById("queryInputBox")
          queryIputBox?.focus()
        }
        else{
          notification.open({
            message: 'Fialed to change model!',
            description:
              '',
            onClick: () => {
              //console.log('Notification Clicked!');
            },
          });
        }
    }
  else
    {
      
    }
  }

useEffect(
  ()=>{
      getModels();          
  },[refresh]
);

  if (user.isLogin)
  return ( 
      <Content>
      <Layout
      >
        <Sider width={200}>

          <Menu
            mode="inline"
            defaultSelectedKeys={['chats']}
            defaultOpenKeys={[]}
            style={{
              height: '100%',
            }}
            onClick = {handleClick}
            
            items={sideBar}
          />
        </Sider>
        <Content
          style={{
            padding: '10px 20px',
            minHeight: '100%',
            backgroundColor:'#fff'
          }}
        >
        <TextArea
        id="queryInputBox"
        placeholder="Press enter to submit your querry"
        autoSize={{ minRows: 1, maxRows: 6 }}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {if (e.keyCode === 13) e.preventDefault();}}
        disabled = {disableInput}
        onPressEnter={()=>{
          const pullMessage:Query = {
            query: input,
          }
          //console.log(pullMessage)
          setInput('')
          setDisableInput(true)
          getResponse(pullMessage);
          
        }}
        />
        <div style={{ margin: '24px 0' }} />
        <Markdown md={currentMdFile}/>
        </Content>
      </Layout>
      </Content>
);
else 
return(<Content><Alert message="Error! Please login first" type="error" showIcon />
</Content>)
        }
export default Chat;
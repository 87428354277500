import React,{useReducer} from 'react';
import {Layout} from 'antd';
import LNavbar from './LNavbar';
import LFooter from './LFooter';
import LRouter from './router/LRouter';
import {BrowserRouter as Router} from 'react-router-dom';
import {initialUserState,reducer,RootContext} from './reducer';

const App: React.FC = () => {
    let storedUser_string = localStorage.getItem("lisite_user")
    let storedUser = storedUser_string?JSON.parse(storedUser_string):initialUserState
    const [userLoginState,userLoginDispatch] = useReducer(reducer,storedUser)
    
    return(
        <RootContext.Provider value={{user:userLoginState,dispatch:userLoginDispatch}}>
            <Router>
                <Layout>
                    <LNavbar />
                    <LRouter />
                    <LFooter />
                </Layout>
            </Router>
        </RootContext.Provider>
    );
}
export default App;
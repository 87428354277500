import {createContext} from 'react';
interface loginAction {
    type:string,
    email:string,
    token:string
}

export const initialUserState = {
    userEmail:'',
    userToken:'',
    isLogin:false
}

export interface context {
    user:typeof initialUserState,
    dispatch:React.Dispatch<loginAction>,
}

export const RootContext = createContext<context>({user:initialUserState,dispatch:()=>{}});

export const reducer:React.Reducer<typeof initialUserState,loginAction> = (user,action) => {
    const {type,email,token} = action;
    switch (type) {
        case 'loginSuccess':
            return {
                userEmail:email,
                userToken:token,
                isLogin:true
            }
        case 'loginFailed':
            return {
                userEmail:email,
                userToken:'',
                isLogin:false
            }
        default:
            return user
    }
}
import { Layout,Affix } from 'antd';
import React from 'react';

const { Footer} = Layout;

const LFooter: React.FC = () => (
    <Affix offsetBottom={0}>
    <Footer style={{ textAlign: 'center',fontSize:'12px',backgroundColor:'#fff' }}>
        Li's Group ©2022. Address: School of Physics and Technology, Wuhan University, Hubei Province, China.
    </Footer>
    </Affix>
);

export default LFooter;
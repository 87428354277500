import { Layout, Menu, Button, Affix, notification } from 'antd';
import { FC, useState,useEffect, useLayoutEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { RootContext } from '../../reducer';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/darcula.css';
import 'codemirror/mode/markdown/markdown';
import './Admin.css';

const { Content, Sider } = Layout;
interface Items {
  key: string;
  label: string;
  children?: Items[];
}
interface Query {
  key:string;
  path:string;
  queryType:string
}
interface SubKey {
  title:string;
  key:string;
  path:string
}

const Admin:FC = () => {
  const {user,dispatch} = useContext(RootContext);   
  const xsterItems:Items[] = [
    {key:'introduction',
     label:'Introduction'},
    { 
      key:'softwares',
      label:'Softwares',
      children:[]
    },
    {
      key:'system',
      label:'System settings',
      children:[]
    },
    { key:'linux',
      label:'Linux tips',
      children:[]
    },
    {
      key:'add',
      label:'Add new'
    }
  ]
  const selectedSiderBarItem = useRef(({title:'introduction',key:'xster',path:'.introduction'} as SubKey))
  const editorRef:React.MutableRefObject<string> = useRef('');
  const [refresh,setrefresh] = useState(false);
  const doRefresh = ()=> setrefresh(true);
  const [currentMdFile,setcurrentMdFile] = useState<string>('');
  const [xsterSiderBar,setxsterSiderBar] = useState<Items[]>(xsterItems);
  //const [count,setcount] = useState(0);
  const xster_intro_url = '/circle9/xster.introduction/0';
  const [xsterSiderBarSubKeys,setxsterSiderBarSubKeys] = useState<SubKey[]>([]);
  const xsterSiderBarQueryKeys = [
  {'key':'xster','path':'.softwares','queryType':'subKey'},
  {'key':'xster','path':'.system','queryType':'subKey'},
  {'key':'linuxTips','path':'','queryType':'subKey'}
  ]
  const subKeys:SubKey[] = [];
  const updateEditorRef = (ref:string) => {
    editorRef.current = ref;
    //console.log(diffLines(initial,ref));
  }
  const getXsterSiderBar =
  async(queries:Query[])=>{
    if (refresh === true){
    for (var j=0;j<3;j++){
      const promise = await axios.post('/circle9/pull',queries[j]);
      let data = promise.data;
      //console.log(data)
      for(var i=0;i<data['subKey'].length; i++){
        let title = data['title'][i];
        (xsterItems[j+1].children as Items[]).push({"key":title,"label":title});
        subKeys.push({'title':title,'key':data['subKey'][i],'path':data['path'][i]})
      }
    }
  }
    setxsterSiderBar(xsterItems);
    setxsterSiderBarSubKeys(subKeys);
    if (refresh === false)
      doRefresh();
    //console.log(refresh);
}

useLayoutEffect(
  ()=>{
      getXsterSiderBar(xsterSiderBarQueryKeys);          
  },[refresh]
);

  const getXsterMdFile = async (url:string)=>{
    const promise = await axios.get(url);
    setcurrentMdFile(promise.data);
    //console.log(promise.data);  
  }
  useEffect(
    ()=>{
      getXsterMdFile(xster_intro_url);          
    },[]
  );
  
  const handleSiderBarClick = (e:any) => {
    if (e.key==='introduction')
      {
        getXsterMdFile(xster_intro_url);
        selectedSiderBarItem.current = {title:'introduction',key:'xster',path:'.introduction'}
      }
    else if (e.key === 'add')
      {
        setcurrentMdFile('{\n  "path":"",\n  "key":"",\n  "item":"",\n  "type":"add",\n  "rkey":"",\n  "rpath":""\n}')
        selectedSiderBarItem.current = {title:'Add New',key:'add',path:''}
      }
    else
      {
        let link = xsterSiderBarSubKeys.find((value)=>{return value.title === e.key})
        if (typeof link !== undefined)
          selectedSiderBarItem.current = link as SubKey;
        var query_url = '/circle9/'
        
        query_url = query_url+link?.key+link?.path+'/7';
        getXsterMdFile(query_url)
      }
    }
    ///*
    setTimeout(() => {
      const $el = document.querySelector('.CodeMirror');
      if ($el) {
        $el.setAttribute('style', 'min-height:100%;width:100%;padding-right:5px;box-shadow:none');
      }
    }, 100);
  
const saveChange=async ()=>{
  let tp = 'modify';
  if (selectedSiderBarItem.current.key === 'add')
    tp = 'add';
  
  //let userToken = sessionStorage.getItem("lisite");
  const promise = await axios.post('/circle9/push',
  {
    "type":tp,
    "key":selectedSiderBarItem.current,
    "text":editorRef.current},
    {headers:{"Authorization":"JWT "+user.userToken}
  });      
  //console.log(promise.data)
  
  notification.open({
    message: JSON.stringify(promise.data),
    description:
      '',
    onClick: () => {
      //console.log('Notification Clicked!');
    },
  });  
}
  return ( 
      
      <Layout
      >
        <Sider width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['introduction']}
            defaultOpenKeys={[]}
            style={{
              height: 2000,
            }}
            onClick = {handleSiderBarClick}
            items={xsterSiderBar}
          />
        </Sider>
        <Content
          style={{
            padding: '0px 0px',
            backgroundColor:'#fff'
          }}
        >
          <CodeMirror
            value= {currentMdFile}
            options={{
            mode: 'markdown',
            theme: 'darcula',
            lineNumbers: true,
            lineWrapping: true
            }}
            onChange={(editor, data, value) => {updateEditorRef(value)}}
          />
          <Affix offsetBottom={60}>
            <Button 
              type='primary'
              ghost danger
              style={{'float':'right','marginRight':10}}
              onClick={saveChange}
            >
              Save
            </Button>
          </Affix>
        </Content>
      </Layout>
      
);
        }

export default Admin;
import { Content } from "antd/lib/layout/layout";
import React, { Component } from "react";


class Research extends Component{
    render() {
        return (
            <Content>
                testing!
            </Content>
            
        );
    }
}
export default Research;